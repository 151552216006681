<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="organization">
        <div class="man-title">
          水表属性
          <el-button @click="addModel()" class="btn" type="primary" size="mini">新增名称</el-button>
        </div>
        <div class="table-tree">
          <div class="tree">
            <div class="tree-title"><i class="el-icon-share"></i>字典管理</div>
            <el-tree
              :data="treeData"
              node-key="attributeCode"
              @node-click="treeRowClick"
              :props="defaultProps"
              :expand-on-click-node="false"
              highlight-current
              :current-node-key="attributeCode"
              default-expand-all
              ref="tree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          
          <div class="table">
            <el-table :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="attributeContent" label="名称"></el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        操作<i class="el-icon-arrow-down"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="editModel(scope.row)">编辑</div></el-dropdown-item>
                      <el-dropdown-item><div @click="dltModel(scope.row)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 新增名称 -->
        <el-drawer
          title="新增名称"
          :with-header="false"
          :visible.sync="createModelDrawer"
          direction="rtl"
          size="800px">
          <div class="drawer-title">新增名称</div>
          <el-form style="padding: 20px;" :model="form" :rules="rules" ref="modelRuleForm" label-width="100px">
            <el-form-item label="名称：" prop="attributeContent" label-width="130px">
              <el-input style="width:90%;" v-model="form.attributeContent" type="text" placeholder="请输入名称" maxlength="50" show-word-limit/>
            </el-form-item>
          </el-form>
          <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="createModelDrawer=false">取消</el-button>
            <el-button
              size="small"
              type="primary"
              @click="createModelDrawerOK"
              class="drawer-bottom-ok">确 定</el-button>
          </div>
        </el-drawer>

        <!-- 编辑名称 -->
        <el-drawer
          title="编辑名称"
          :with-header="false"
          :visible.sync="editModelDrawer"
          direction="rtl"
          size="800px">
          <div class="drawer-title">编辑名称</div>
          <el-form style="padding: 20px;" :model="editModelForm" :rules="rules" ref="editRuleForm" label-width="100px">
            <el-form-item label="名称：" prop="attributeContent" label-width="130px">
              <el-input style="width:90%;" v-model="editModelForm.attributeContent" type="text" placeholder="请输入名称" maxlength="50" show-word-limit/>
            </el-form-item>
          </el-form>
          <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="editModelDrawer=false">取消</el-button>
            <el-button
              size="small"
              type="primary"
              @click="editModelDrawerOK"
              class="drawer-bottom-ok">确 定</el-button>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableLoading: false,
      createModelDrawer: false,
      editModelDrawer: false,
      form: {},
      editModelForm: {},
      rules: {
        attributeContent: [{ required: true, message: '必填项', trigger: 'blur' }]
      },
      treeData: [
        { attributeCode: 'useProperty',name: '用水性质' },
        { attributeCode: 'waterMeterType',name: '水表类型' },
        { attributeCode: 'waterMeterCaliber',name: '水表口径' },
        { attributeCode: 'waterMeterModel',name: '水表型号' },
        { attributeCode: 'waterMeterValveStatus',name: '阀门状态' },
        { attributeCode: 'waterMeterReadType',name: '抄表方式' },
        { attributeCode: 'waterMeterRunningStatus',name: '水表状态' },
        { attributeCode: 'waterMeterInstallationPosition',name: '安装位置' },
        { attributeCode: 'waterMeterReportAbnormal',name: '异常分类' }
      ],
      tableData: [],
      roleId: 0,
      attributeCode: '',
      defaultProps: {
        children: 'childTree',
        label: 'name'
      }
    }
  },
  methods: {
    // 切换字典
    treeRowClick(data) {
      this.attributeCode = data.attributeCode
      sessionStorage.setItem('attributeCode',data.attributeCode)
      this.loadPropertyRight(data.attributeCode)
    },

    // 新增
    addModel() {
      this.createModelDrawer = true
    },

    // 编辑
    editModel(row) {
      this.editModelDrawer = true
      this.editModelForm = row
    },

    // 删除名称
    dltModel(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        this.deletePropertyRight(row.id)
      })
    },

    // 新增名称
    createModelDrawerOK() {
      this.$refs.modelRuleForm.validate((valid) => {
        if (valid) {
          this.createPropertyRight(this.attributeCode)
          this.form = {}
          this.createModelDrawer = false
        }
      })
    },

    // 编辑名称
    editModelDrawerOK() {
      this.$refs.editRuleForm.validate((valid) => {
        if (valid) {
          this.updatePropertyRight()
          this.editModelForm = {}
          this.editModelDrawer = false
        }
      })
    },

    loadPropertyRight(attributeCode) {
      this.loading = true
      this.$ajax.post('meterAttrQuery',{
        attributeCode: attributeCode
      }).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    createPropertyRight(attributeCode) {
      this.loading = true
      this.$ajax.post('meterAttrCreate', {
        attributeContent: this.form.attributeContent,
        attributeCode: attributeCode
      }).then(res => {
        this.loading = false
        this.loadPropertyRight(this.attributeCode)
        this.$message.success('创建成功')
      }).catch(err=>{
        this.loading = false
        this.$message.error('创建失败')
      })
    },
    deletePropertyRight(id) {
      this.loading = true
      this.$ajax.post('meterAttrDelete', {
        id: id
      }).then(res => {
        this.loading = false
        this.loadPropertyRight(this.attributeCode)
        this.$message.success('删除成功')
      }).catch(err=>{
        this.loading = false
        this.$message.error('删除失败')
      })
    },
    updatePropertyRight() {
      this.loading = true
      this.$ajax.post('meterAttrUpdate', {
        id: this.editModelForm.id,
        attributeContent: this.editModelForm.attributeContent,
      }).then(res => {
        this.loading = false
        this.loadPropertyRight(this.attributeCode)
        this.$message.success('编辑成功')
      }).catch(err=>{
        this.loading = false
        this.$message.error('编辑失败')
      })
    },
  },
  mounted() {
    if(sessionStorage.getItem('attributeCode')){
      let attributeCode = sessionStorage.getItem('attributeCode')
      this.attributeCode = attributeCode
      setTimeout(()=>{
        this.$refs.tree.setCurrentKey(attributeCode)
      },300)
      this.loadPropertyRight(attributeCode)
    }else{
      this.attributeCode = 'useProperty'
      this.loadPropertyRight(this.attributeCode)
    }
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('attributeCode')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.organization {
  height: 100%;
}
.btn {
  float: right;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
  
}
.company-name {
  font-size: 18px;
  padding: 10px 0;
  margin: 0 10px;
  line-height: 28px;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>